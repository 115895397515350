$primary:       #6d9b27 !default;
$secondary:     #898989 !default;
$white:         #fff !default;
$gray-100:      #f1f4f7 !default;
$gray-200:      #e9ecef !default;
$gray-300:      #dee2e6 !default;
$gray-400:      #ced4da !default;
$gray-500:      #9096A2 !default;
$gray-600:      #6c757d !default;
$gray-700:      #4D4D57 !default;
$gray-800:      #343a40 !default;
$gray-900:      #1D1F24 !default;
$black:         #09090B !default;

$theme-colors: (
    "primary": #6d9b27,
    "secondary": #898989,
    "light": #fafafa,
    "dark": #0f141a,
    "grey": #374252) !default;
    
$very-light: #969ca6;

$spacer: 1rem;
$enable-rounded: true;

$border-width: 1px !default;
$border-color: rgba(map-get($theme-colors, dark), 0.1) !default;

$border-radius: 7px !default;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Spartan:wght@300;400&display=swap');

$font-family-base:          'Open Sans', sans-serif !default;
$font-family-sans-serif:    'Open Sans', sans-serif !default;
$font-family-serif:         'Spartan', sans-serif !default;
$navbar-font-family: 'Open Sans', sans-serif !default;

$font-size-base: 1rem !default; 
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 1.875 !default;
$h4-font-size: $font-size-base * 1.625 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1 !default;

$headings-margin-bottom: 1rem !default;
$headings-font-family: $font-family-serif !default;
$headings-font-weight: 400 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.75rem !default;

$display1-weight: 400 !default;
$display2-weight: 400 !default;
$display3-weight: 400 !default;
$display4-weight: 400 !default;
$display-font-family: $font-family-serif !default;
$display-line-height: 1.1 !default;

$lead-font-size: ($font-size-base * 1.5) !default;
$lead-font-weight: 300 !default;
$lead-line-height: 1.5 !default;

$small-font-size: 0.75rem !default;
$small-2-font-size: 0.9rem !default;
$small-line-height: 1.4 !default;

$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba(map-get($theme-colors, dark), 0.1) !default;
$hr-border-width: 1px !default;

$input-placeholder-color: #b7bbc0;
$input-border-color: $border-color;
$input-padding-y: 15px;
$input-padding-x: 20px;
$input-box-shadow: none;

$card-spacer-x: 1.875rem;

$body-color: #000;

$shadow-0: none;
$shadow-1: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
$shadow-2: 0px 13px 40px rgba(0, 0, 0, 0.2);

$drop-down-color: map-get($theme-colors, dark);
$drop-down-background: #fff;
$drop-down-hover-color: map-get($theme-colors, dark);
$drop-down-hover-background: rgba(map-get($theme-colors, dark), 0.1);
$drop-down-info-font-weight: 600;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), 0 -18px 60px -10px rgba(0,0,0,.025) !default;


/*
 * 	Navigation Top Level Items
 */

$nav-font-size:             14px;
$nav-font-weight:           700;
$nav-font-style:            normal;
$nav-text-transform:        normal;
$nav-line-height:           1.4em;
$nav-letter-spacing:        0;
$nav-color:                 rgba($white, 0.8);
$nav-margin:                0 10px;
$nav-padding:               10px 5px;

$nav-hover-background:      transparent;
$nav-hover-color:           $white;
$nav-current-color:         $white;
$nav-current-background:    transparent;

/*
 * 	Navigation Drop Down Items
 */

$nav-dropdown-background:           $white;
$nav-dropdown-border-radius:        $border-radius;
$nav-dropdown-border:               none;
$nav-dropdown-box-shadow:           $box-shadow-lg;
$nav-dropdown-container-margin:     0 20px 0 0;
$nav-dropdown-container-padding-y:  14px;
$nav-dropdown-container-padding-x:  7px;
$nav-dropdown-item-padding-y:       0.5rem;
$nav-dropdown-item-padding-x:       1rem;
$nav-dropdown-font-family:          $font-family-base;
$nav-dropdown-font-size:            13px;
$nav-dropdown-font-weight:          400;
$nav-dropdown-font-style:           normal;
$nav-dropdown-text-transform:       normal;
$nav-dropdown-line-height:          1.4em;
$nav-dropdown-letter-spacing:       0;
$nav-dropdown-second-color:         $gray-800;
$nav-dropdown-third-color:          $gray-700;

$nav-dropdown-hover-background:     rgba(0,0,0,0.1);
$nav-dropdown-hover-color:          $gray-900;
$nav-dropdown-current-background:   transparent;
$nav-dropdown-current-color:        $primary;

/*
 * 	Mobile Navigation
 */

 $mobile-nav-font-size:             12px;
 $mobile-nav-line-height:           1.4em;
 $mobile-nav-padding:               10px 15px;
 $mobile-current-background:        transparent;
 $mobile-current-color:             $gray-200;

@import "bootstrap/bootstrap.scss";
@import "bootstrap/bootstrap-grid.scss";
@import "bootstrap/bootstrap-reboot.scss";
@import "bootstrap/style-specific.scss";
@import "navbar";

#quickLinks {
    svg, i {
        &:not([class*="angle-down"]) {
            display: none !important;
        }
    }
}
#homeOwlCarousel {
    > div {
        @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
            height: 600px !important;
        }
    }
}

#callToActions {
    .panel-image {
      transition: 0.4s ease;
    }
}

.panel-image {
    overflow: hidden !important;
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
    }
}

.section-blog {
    .card {
        .btn-primary {
            display: none;
        }
        svg, i {
            display: none;
        }
        .text-muted.small {
            a {
                margin: 0 4px;
            }
        }
    }
}

.section-testimonials {
    .h6 {
        font-weight: 400 !important;
    }
    .pr-3 {
        display: none !important;
    }
}

.section-team {
    .text-muted {
        color: rgba($white, 0.8) !important;
    }
}

[style="min-height: 700px;"], [style="min-height: 600px;"] {
    @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
        min-height: 1px !important;
    }
}

#footer [id*="OfficeRepeater"] > * {
    @media screen and (max-width: map-get($grid-breakpoints, lg) - 1) {
        display: block;
        &.mx-2 {
            display: none;
        }
    }
}
.panel-video-container{position:relative;}.panel-video,.panel-video-overlay{position:absolute;top:0;right:0;bottom:0;left:0;z-index:0;height:100%;box-sizing:content-box;overflow:hidden}.panel-video video{position:absolute;top:50%;left:50%;-webkit-transform:translate3d(-50%,-50%,0);-moz-transform:translate3d(-50%,-50%,0);transform:translate3d(-50%,-50%,0)}

.phone-email-validator[style*="inline"] {
    text-align: left !important;
    display: block !important;
}

.region:after {
    content: ' '; 
}